.react-datepicker__close-icon {
  padding: 0 16px 0 0;
}

.react-datepicker {
  display: flex;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  border-color: var(--border-main-color);
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
}
.calendar--dropdown {
  position: absolute;
}
.react-datepicker-popper {
  z-index: 4;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  &::before {
    border-bottom-color: var(--border-main-color);
  }
  &::after {
    border-bottom-color: #fff;
  }
}

.react-datepicker__header {
  background: #f7f8f9;
}

.react-datepicker__close-icon::after {
  background-color: var(--main-color);
}

.react-datepicker__day--selected {
  background-color: var(--main-color);
}

.main--dark,
.popup-window--black {
  .react-datepicker,
  .react-datepicker__time-container {
    background: #252a2e;
    border-color: #32383e;
  }
  .react-datepicker__header {
    background: #252a2e;
    border-color: #32383e;
  }
  .react-datepicker__time-container {
    border-color: #32383e;
    .react-datepicker__time {
      background: #252a2e;
      .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item:hover {
        color: #000;
      }
    }
  }
  .react-datepicker__current-month {
    color: #fff;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #fff;
    &:hover {
      color: #000;
    }
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-top-color: #32383e;
    border-bottom-color: #32383e;
  }
  .input {
    svg path {
      fill: #fff;
    }
  }
}

.react-datepicker-wrapper {
  position: relative;
  z-index: 2;
  .input-item {
    background-color: transparent;
  }
}

.input--datepicker {
  background-color: #fff;
  border-radius: 5px;
}
