.pagination > li > a,
.pagination > li > button {
  cursor: pointer;
}

.pagination {
  > li {
    .arrow {
      svg {
        width: 10px;
        height: auto;
      }
    }
    &.disabled {
      opacity: 0.3;
    }
  }
}

.table-filter--search-only {
  grid-template-columns: 44px 481px 54px;
}

.table-no-data-body,
.table-no-data-body-plug {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-no-data-body-plug {
  margin: 16px 0;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #bbbec7;
  text-align: center;
}

.td-status {
  &.new {
    background: #4a7a88;
  }
  &.canceled {
    background: #f44336;
  }
}

.table-buttons {
  svg {
    width: 20px;
    height: 20px;
  }
}

.table--user-trade-history .tr {
  grid-template-columns:
    1.4fr minmax(60px, 0.5fr) 0.4fr 0.5fr minmax(65px, 0.75fr) 0.6fr minmax(65px, 0.75fr)
    0.6fr 0.5fr 0.5fr 0.5fr minmax(72px, 0.5fr) minmax(100px, 0.5fr);
}

.table--trade-history .tr {
  grid-template-columns:
    1.4fr minmax(60px, 0.5fr) minmax(60px, 0.6fr) 0.4fr minmax(50px, 0.4fr) minmax(65px, 0.75fr)
    0.65fr minmax(65px, 0.75fr) 0.65fr 0.5fr 0.5fr 0.4fr minmax(72px, 0.45fr) minmax(40px, 0.5fr) minmax(100px, 0.5fr);
}

.button--type-icon {
  background: none;
  border: none;
  border-radius: 4px;
  height: 23px;
  min-width: 23px;
  padding: 0;
  width: 23px;
  &:hover {
    background: rgba(50, 56, 62, 0.05);
  }
}

button.td-value {
  font-size: inherit;
}

.td .td-flex__drop-btn {
  svg {
    width: 10px;
    height: 6px;
    path {
      stroke: #000;
    }
  }
}

.search-container {
  position: relative;
}
.table-filter--trade .dropdown-table {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 10;
  .table-wrapper {
    max-height: 350px;
    overflow: auto;
  }
  .table-block {
    margin-top: 5px;
  }
  .table-dropdown-table {
    .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
