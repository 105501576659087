.show-pass {
  width: 24px;
}

.button--unlock {
  opacity: 0.3;
  pointer-events: none;
}

.blocked {
  .button--lock {
    opacity: 0.3;
    pointer-events: none;
  }
  .button--unlock {
    opacity: 1;
    pointer-events: initial;
  }
}

.is-deleted {
  .table-buttons {
    opacity: 0.3;
    pointer-events: none;
  }
}

.inactive {
  color: #f44336;
  path {
    fill: #f44336;
  }
}

.activated {
  color: #00da83;
}
.pending {
  color: #ff9f0a;
}
.rejected {
  color: #f44336;
}

.td-status {
  &.waiting {
    background: #4a7a88;
  }
}

.popup-overlay {
  background: rgba(1, 30, 38, 0.5);
}

.popup-open {
  overflow: hidden !important;
}
