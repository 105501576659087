@media screen and (max-width: 1199px) {
  .td-hidden-name {
    display: block;
  }

  .table-header {
    display: none;
  }

  .tr {
    display: flex;
    flex-wrap: wrap;
  }

  .td {
    width: 50%;
    padding: 5px 10px;
    text-align: left;
    &:first-child {
      padding-left: 10px;
      padding-top: 10px;
    }
    &:last-child {
      padding-right: 10px;
      padding-bottom: 10px;
    }
  }

  .stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-info {
    width: 100%;

    &__block {
      margin-right: 30px;
    }
    & + .button {
      margin-top: 20px;
    }
  }

  .chart {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .stats__info {
    margin-right: 0;
  }

  .content-block {
    width: calc(100% + 30px);
    margin-left: -15px;
    &__inside {
      padding: 15px;
    }
  }

  .table-transaction {
    margin-top: 0;
  }

  .mob-nav-btn {
    display: flex;
  }

  .sidebar {
    display: none;
    width: 100% !important;
    padding: 12px;
    &__action {
      display: none;
    }
  }

  .main-content {
    width: 100% !important;
  }

  .main-section {
    flex-wrap: wrap;
    &.minimized {
      .sidebar {
        display: block;
        padding: 12px;
      }
      .sidebar-nav__list {
        > li {
          > a,
          > button {
            justify-content: flex-start;
            font-size: 16px;
            padding: 12px 15px;
          }
        }
      }
    }
  }

  .sidebar-nav__icon {
    margin: 0 10px 0 0 !important;
  }

  .sidebar-nav__list {
    > li {
      > a,
      > button {
        padding: 12px 15px;
      }
    }
  }

  .forms-block--big .form {
    padding: 20px 15px;
  }

  .reports {
  }
  .report-item {
    width: calc(50% - 8px);
  }

  .connect-data {
    max-width: 100%;
  }

  .main-content--flex {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .connect-data {
    padding: 20px 15px;
  }

  .form--second-type {
    flex-wrap: wrap;
    justify-content: flex-start;
    .form__content {
      flex: 0 0 100%;
      padding: 0;
    }
  }

  .connect-data__send {
    margin: 30px auto 0 auto;
  }

  .reports {
    padding-top: 15px;
  }

  .report-item {
    width: 100%;
    margin: 15px 0 0 0;
    padding: 20px 15px;
  }

  .level {
    margin: 15px 0 0 0;
  }

  .forms-row {
    gap: unset;
  }

  .forms-block {
    width: 100%;
    margin-top: 15px;
    padding: 0;
    .form {
      margin-top: 0;
    }
  }

  .form {
    width: 100%;
    margin-top: 15px;
  }

  .stats__list {
    .stats-item {
      width: 100%;
      &--small {
        width: calc(50% - 8px);
      }
    }
  }

  .panel {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 22px;
  }

  .filter {
    width: 100%;
    margin: 10px 0 0 0;
    &:last-child {
      margin: 10px 0 0 0;
    }
  }
}

@media screen and (max-width: 400px) {
  .td {
    width: 100%;
  }
}
