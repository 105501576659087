@import '_reset.scss';
@import 'admin.scss';
@import '_ui-change.scss';

:root {
  --main-color: #3f51b5;
  --main-color-hover: #2a3bad;
  --text-main-color: #011e26;
  --text-main-color2: #71a2b0;
  --text-main-color3: #777e90;
  --text-color-white: #fff;
  --border-main-color: #e6e8ec;
  --border-color2: #d9d9d9;

  --red-color: #f44336;
}

body {
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background: #f1f3f8;
  font-family: 'Inter', sans-serif;
  line-height: 1.4;
  color: var(--text-main-color);

  &.no-scroll {
    overflow-y: hidden;
  }

  button {
    font-family: 'Inter', sans-serif;
  }

  a {
    color: var(--text-main-color);
  }

  input {
    font-family: 'Inter', sans-serif;

    &::placeholder {
      font-size: inherit;
      font-family: 'Inter', sans-serif;
    }
  }

  textarea {
    font-family: 'Inter', sans-serif;

    &::placeholder {
      font-size: inherit;
      font-family: 'Inter', sans-serif;
    }
  }
}

.wrapper {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.usn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.flex-m {
  align-items: center;
}

.flex-t {
  align-items: flex-start;
}

.flex-b {
  align-items: flex-end;
}

.flex-sb {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-c {
  justify-content: center;
}

.flex-sa {
  justify-content: space-around;
}

.flex-e {
  justify-content: flex-end;
}

.flex-ww {
  flex-wrap: wrap;
}

.mob {
  display: none;
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

section {
  box-sizing: border-box;
  padding: 1px 0;
}

label {
  cursor: pointer;
}

img,
svg {
  width: auto;
  max-width: 100%;
}

svg {
  display: block;
}

/*base*/

.header {
  height: 65px;
  gap: 16px;
  background: var(--main-color);
  &--center {
    justify-content: center;
  }
  &__logo {
    display: flex;
    width: 222px;
    border-radius: 0;
    background: none;
    @media screen and (max-width: 760px) {
      width: 120px;
    }

    img {
      border-radius: 0;
      height: auto;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 991px) {
    height: 56px;
  }
}

.input-icon-text {
  margin-right: 10px;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 400;
}

.input-coin {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  font-weight: 400;
  color: var(--text-main-color3);
}

.input-notification {
  margin-top: 4px;
  font-weight: 500;
  font-size: 11px;

  &__link {
    display: block;
    text-align: right;
    color: #3b97d3;
  }
  &--big {
    font-size: 14px;
  }
}

.link {
  color: var(--link-color);
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}

.authorization-form {
  padding: 24px 38px;
  border: 1px solid var(--border-main-color);
}

.sidebar {
  background: #fff;
  &__action {
    flex: 0 0 24px;
    max-width: 24px;
  }
  &__icon {
    margin: 0;
  }
  .sidebar-nav__list {
    > li {
      > a,
      > button {
        gap: 10px;
      }
    }
  }
  &.active {
    display: block;
  }
  @media screen and (min-width: 991px) {
    &.sidebar--hidden {
      width: 76px;
      & + .main-content {
        max-width: calc(100% - 76px);
      }
      .sidebar-nav__list > li > a {
        white-space: nowrap;
        font-size: 0;
      }
      .sidebar-nav__icon {
        margin: 0;
      }
      .sidebar-nav__list {
        > li {
          > a,
          > button {
            justify-content: center;
            padding: 15px 16px;
            gap: 0;
          }
        }
      }
    }
  }
}

.main-content {
  //max-width: 1180px;
  flex: 1;
  width: unset;
  background: #f1f3f8;
  max-width: calc(100% - 250px);
  @media screen and (max-width: 991px) {
    padding: 24px 16px;
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
}

.main-section {
  min-height: calc(100vh - 82px);
}

.sidebar-nav {
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
  &__list {
    > li {
      > a,
      button,
      ul > li > a,
      ul > li > button {
        display: flex;
        width: 100%;
        padding: 15px 16px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        color: #727272;
        transition: all 0.3s ease;
        svg {
          .stroke {
            stroke: #727272;
            transition: 0.3s;
          }
          .fill {
            fill: #727272;
            transition: 0.3s;
          }
        }

        &:hover,
        &.active {
          color: #fff;
          background: var(--main-color);

          svg {
            .stroke {
              stroke: #fff;
            }
            .fill {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

.title-block {
  margin-bottom: 16px;
  font-size: 29px;
  gap: 16px;
  color: var(--text-main-color);
  @media screen and (max-width: 720px) {
    width: 100%;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }
  &--with-mt {
    margin-top: 24px;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
}

.title-block-button {
  display: flex;
  align-items: center;
  gap: 16px;
  &--flex-end {
    justify-content: flex-end;
  }
}

.title-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-left: auto;
  @media screen and (max-width: 760px) {
    margin-left: 0;
  }
  @media screen and (max-width: 560px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 475px) {
    grid-template-columns: 1fr;
  }
}

.table-filter {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 44px 2fr 1fr 1fr 140px;
  gap: 8px;

  > * {
    margin: 0;
  }
  &--mt-type2 {
    margin-top: 40px;
    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }
  }
  &--base-filter {
    @media screen and (max-width: 760px) {
      > * {
        &:nth-child(1) {
          order: 10;
          //width: 100%;
        }
      }
    }
  }
  &--clients {
    grid-template-columns: 44px 2fr 1fr 1fr 0.9fr 0.9fr;
    align-items: flex-end;
    @media screen and (max-width: 760px) {
      > * {
        &:nth-child(1) {
          order: 10;
          //width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &--search-only {
    grid-template-columns: 44px 437px;
    @media screen and (max-width: 760px) {
      grid-template-columns: 44px 1fr;
    }
  }
  &--trade-account {
    display: flex;
    .input {
      width: 255px;
    }
    @media screen and (max-width: 760px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .input {
        width: auto;
      }
    }
    @media screen and (max-width: 480px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      .input {
        width: auto;
      }
    }
  }
  &--user-trade-history {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media screen and (max-width: 860px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 520px) {
      grid-template-columns: 1fr;
    }
  }
  &--transactions {
    display: flex;
    flex-wrap: wrap;
    .search-by {
      width: 488px;
      @media screen and (max-width: 1199px) {
        width: calc(100% - 52px);
      }
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
    > .input {
      width: 192px;
      @media screen and (max-width: 1199px) {
        width: calc(50% - 4px);
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }
    > * {
      @media screen and (max-width: 760px) {
        &:nth-child(1) {
          order: 10;
          //width: 100%;
        }
      }
    }
  }
  &--flex-type {
    display: flex;
    flex-wrap: wrap;
    > .input {
      width: 192px;
      @media screen and (max-width: 1199px) {
        width: calc(50% - 4px);
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }
    > * {
      @media screen and (max-width: 760px) {
        &:nth-child(1) {
          order: 10;
          //width: 100%;
        }
      }
    }
    .clients-filter-input {
      width: 400px;
    }
  }
  &--trade {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 0;
    & + .table-filter {
      margin-top: 16px;
      @media screen and (max-width: 520px) {
        margin-top: 8px;
      }
    }
    > div {
      width: 100%;
    }

    .table-filter-btns {
      width: auto;
    }
    @media screen and (max-width: 520px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 420px) {
      display: grid;
      grid-template-columns: 1fr;
    }
    &--second {
    }
  }
  &--deposits {
    grid-template-columns: 44px 2fr 1fr 1fr 140px;
    gap: 8px;
    margin-bottom: 16px;
    .search-by {
      @media screen and (max-width: 1199px) {
        width: 100%;
      }
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
    > .input {
      @media screen and (max-width: 1199px) {
        width: calc(50% - 4px);
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }
    > * {
      @media screen and (max-width: 760px) {
        &:nth-child(1) {
          order: 4;
          //width: 100%;
        }
        &:nth-child(5) {
          order: 5;
          width: calc(100% - 52px);
        }
      }
    }
    @media screen and (max-width: 1199px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &--deposits-second {
    display: flex;
    > * {
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
  }
}

.table-filter-btns {
  display: flex;
  align-items: center;
}

.filter {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  @media screen and (max-width: 760px) {
    width: 100%;
  }
}

.search-by {
  display: flex;
  .select {
    flex: 0 0 120px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    @media screen and (max-width: 760px) {
      flex: 0 0 100px;
    }
  }
  .input {
    margin-bottom: 0;
  }
  .input-item {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.table-block {
  margin-top: 32px;
  border-radius: 10px;
  border: 1px solid var(--border-main-color);

  &--mb-24 {
    margin-bottom: 24px;
  }
  &--mt-16 {
    margin-top: 16px;
  }
  &--width-420 {
    max-width: 420px;
  }
  &--br-l-0 {
    border-radius: 0 8px 8px 8px;
  }
  &--br-r-0 {
    border-radius: 8px 0 8px 8px;
  }
  &--border-none {
    border: none;
  }
  &--full-block {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  @media screen and (max-width: 1199px) {
    margin-top: 16px;
  }
}

.table {
  border: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 180%;

  &--user-management {
    & .tr {
      grid-template-columns: 1.42fr 1.57fr 2.36fr 1.75fr 1.82fr 1.53fr 1fr;
    }
  }
  &--user-management-8 {
    & .tr {
      grid-template-columns: 1.42fr 1.57fr 2.36fr 1.75fr 1.82fr 1.53fr 1.53fr 1fr;
    }
  }
  &--user-management-9 {
    & .tr {
      grid-template-columns: 1.42fr 1.57fr 2.36fr 1.75fr 1.82fr 1.53fr 1.53fr 1.53fr 1fr;
    }
  }
  &--user-management-10 {
    & .tr {
      grid-template-columns: 1.42fr 1.57fr 2.36fr 1.75fr 1.82fr 1.53fr 1.53fr 1.53fr 1.53fr 1fr;
    }
  }
  &--user-management-11 {
    & .tr {
      grid-template-columns: 1.42fr 1.57fr 2.36fr 1.75fr 1.82fr 1.53fr 1.53fr 1.53fr 1.53fr 1.53fr 1fr;
    }
  }
  &--user-management-new {
    & .tr {
      grid-template-columns: 1.1fr 1.6fr 1.5fr 2.3fr 1.7fr 1.8fr 1.5fr 1fr;
    }
  }

  &--admin-management {
    & .tr {
      grid-template-columns: 0.6fr 1.1fr 1fr 1fr 0.8fr 0.6fr;
    }
    .td-input {
      width: 190px;
    }
  }

  &--admin-permissions {
    & .tr {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--limitation-withdrawal {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 0.6fr;
    }
  }

  &--limitation-deposit {
    & .tr {
      grid-template-columns: 1.2fr 1fr 0.6fr;
    }
  }

  &--limitation-leverage,
  &--settings-leverage {
    & .tr {
      grid-template-columns: 0.4fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
    }
  }

  &--commissions-assets {
    & .tr {
      grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &--commissions-withdraw-crypto {
    & .tr {
      grid-template-columns: 1.2fr 1fr 1fr 0.4fr;
    }
  }
  &--commissions-withdraw-fiat {
    & .tr {
      grid-template-columns: 1.2fr 1fr 1fr 0.4fr;
    }
  }
  &--commissions-deposit-crypto {
    & .tr {
      grid-template-columns: 1.2fr 1fr 1fr 0.4fr;
    }
  }
  &--commissions-deposit-fiat {
    & .tr {
      grid-template-columns: 1.2fr 1fr 1fr 0.4fr;
    }
  }

  &--trade-account {
    & .tr {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--transactions-history-deposit {
    & .tr {
      grid-template-columns: 1.7fr 2.75fr 2.25fr 1.7fr 1.8fr 1.45fr 1.8fr 1.45fr;
    }
  }

  &--transactions-history-withdraw {
    & .tr {
      grid-template-columns: 1fr 1.5fr 0.75fr 1.1fr 1.05fr 1.5fr 2.05fr 1.4fr;
    }
  }

  &--referral-payment {
    & .tr {
      grid-template-columns: 2.2fr 2.6fr 2.1fr 1.7fr 1.8fr;
    }
  }

  &--referral-transfer {
    & .tr {
      grid-template-columns: 2.2fr 2.6fr 2.1fr 1.7fr 1.8fr;
    }
  }
  &--user-trade-history {
    .tr {
      grid-template-columns:
        1.4fr minmax(60px, 0.5fr) 0.4fr 0.5fr minmax(65px, 0.75fr) 0.6fr minmax(65px, 0.75fr) 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        minmax(72px, 0.7fr) minmax(100px, 0.5fr);
    }
    .tr-14 {
      grid-template-columns:
        1.4fr minmax(60px, 0.5fr) 0.4fr 0.5fr minmax(65px, 0.75fr) 0.6fr minmax(65px, 0.75fr) 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        minmax(72px, 0.7fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr)!important;
    }
    .tr-15 {
      grid-template-columns:
        1.4fr minmax(60px, 0.5fr) 0.4fr 0.5fr minmax(65px, 0.75fr) 0.6fr minmax(65px, 0.75fr) 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        minmax(72px, 0.7fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr)!important;
    }
    .tr-16 {
      grid-template-columns:
        1.4fr minmax(60px, 0.5fr) 0.4fr 0.5fr minmax(65px, 0.75fr) 0.6fr minmax(65px, 0.75fr) 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        minmax(72px, 0.7fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr)!important;
    }
    .tr-17 {
      grid-template-columns:
        1.4fr minmax(60px, 0.5fr) 0.4fr 0.5fr minmax(65px, 0.75fr) 0.6fr minmax(65px, 0.75fr) 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        minmax(72px, 0.7fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr)!important;
    }
  }
  &--user-trade-positions {
    overflow: auto;
    .table-body {
      min-height: 300px;
    }
    .table-header,
    .table-body {
      min-width: 1700px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
    .tr {
      grid-template-columns:
        1fr 0.6fr 0.5fr minmax(50px, 0.4fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        0.6fr minmax(50px, 0.3fr) minmax(70px, 0.4fr) minmax(70px, 0.4fr);
    }
    .tr-17 {
      grid-template-columns:
        1fr 0.6fr 0.5fr minmax(50px, 0.4fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        0.6fr 0.6fr minmax(50px, 0.4fr) minmax(70px, 0.4fr) minmax(70px, 0.4fr);
    }
    .tr-18 {
      grid-template-columns:
        1fr 0.6fr 0.5fr minmax(50px, 0.4fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        0.6fr 0.6fr 0.6fr minmax(50px, 0.4fr) minmax(70px, 0.4fr) minmax(70px, 0.4fr);
    }

    @media screen and (max-width: 767px) {
      .tooltip-item {
        position: static;
      }
    }
  }
  &--user-trade-pending-orders {
    .tr {
      grid-template-columns: 1.1fr minmax(90px, 0.6fr) 0.5fr 0.6fr 0.7fr 0.7fr 0.6fr 0.9fr minmax(
          70px,
          0.5fr
        );
    }
    @media screen and (max-width: 767px) {
      .tooltip-item {
        position: static;
      }
    }
  }
  &--user-trade-pending {
    .tr {
      grid-template-columns:
        1.1fr 0.5fr minmax(90px, 0.5fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.6fr 0.7fr minmax(30px, 0.2fr)
        minmax(66px, 0.3fr);
    }
    .tr-12 {
      grid-template-columns:
        1.1fr 0.5fr minmax(90px, 0.5fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.6fr 0.7fr 0.7fr minmax(30px, 0.3fr)
        minmax(66px, 0.3fr);
    }
    .tr-13 {
      grid-template-columns:
        1.1fr 0.5fr minmax(90px, 0.5fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.6fr 0.7fr 0.7fr 0.7fr minmax(30px, 0.3fr)
        minmax(66px, 0.3fr);
    }
    @media screen and (max-width: 767px) {
      .tooltip-item {
        position: static;
      }
    }
  }

  &--transactions-deposit {
    & .tr {
      grid-template-columns: 0.5fr 0.5fr 1.1fr 1fr 0.7fr 0.5fr 0.5fr 0.7fr 0.8fr minmax(100px, 0.5fr);
    }
  }
  &--transactions-withdraw-pending {
    & .tr {
      grid-template-columns: 0.5fr 0.5fr 1.3fr 1.2fr 0.8fr 0.4fr 0.4fr 0.6fr 0.6fr minmax(
          110px,
          0.5fr
        );
    }
  }

  &--transactions-withdraw-requests {
    & .tr {
      grid-template-columns: 0.5fr 0.5fr 0.5fr 0.8fr 0.4fr 0.4fr 0.6fr 0.6fr 0.5fr 1.3fr;
    }
  }
  &--transactions-withdraw-canceled-requests {
    & .tr {
      grid-template-columns: 0.8fr 0.8fr 0.8fr 1.1fr 0.8fr 0.8fr 1fr 0.9fr 1fr;
    }
  }

  &--transactions-transfer {
    & .tr {
      grid-template-columns: 0.9fr 0.9fr 1fr 0.8fr 1fr minmax(100px, 0.5fr);
    }
  }
  &--trade-history {
    .tr {
      grid-template-columns:
        1.4fr minmax(60px, 0.5fr) minmax(60px, 0.6fr) 0.4fr minmax(50px, 0.5fr) minmax(65px, 0.75fr)
        0.65fr minmax(65px, 0.75fr) 0.65fr 0.5fr 0.4fr 0.7fr 0.5fr
        minmax(72px, 0.7fr) minmax(100px, 0.5fr);
    }
  }
  &--trade-positions {
    overflow: auto;
    .table-body {
      min-height: 300px;
    }
    .table-header,
    .table-body {
      min-width: 1800px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
    .tr {
      grid-template-columns:
        1fr 1.3fr 0.6fr 0.5fr minmax(50px, 0.4fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.5fr 0.5fr 0.7fr 0.6fr
        0.6fr minmax(50px, 0.3fr) minmax(70px, 0.4fr) minmax(70px, 0.4fr);
    }
    @media screen and (max-width: 767px) {
      .tooltip-item {
        position: static;
      }
    }
  }
  &--trade-pending-orders {
    overflow: auto;
    .table-body {
      min-height: 300px;
    }
    .table-header,
    .table-body {
      min-width: 1680px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
    .tr {
      grid-template-columns: 1fr 0.5fr 1.3fr 1.3fr minmax(90px, 0.5fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.6fr 0.9fr minmax(
          70px,
          0.5fr
        );
    }
    @media screen and (max-width: 767px) {
      .tooltip-item {
        position: static;
      }
    }
  }
  &--trade-pending-live {
    .tr {
      grid-template-columns:
        1.1fr 0.5fr minmax(90px, 0.5fr) 0.4fr 0.5fr 0.6fr 0.6fr 0.6fr 0.7fr minmax(30px, 0.2fr)
        minmax(66px, 0.3fr);
    }
    @media screen and (max-width: 767px) {
      .tooltip-item {
        position: static;
      }
    }
  }
  &--deposits {
    & .tr {
      grid-template-columns: minmax(80px, 0.5fr) minmax(80px, 0.5fr) 1.2fr 1fr 1fr 0.5fr 0.6fr minmax(
          100px,
          0.6fr
        );
    }
  }
  &--with-scroll {
    scrollbar-color: #e6e8ec var(--main-color);
    scrollbar-width: thin;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #e6e8ec;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--main-color);
    }
  }
  &--volume-ranking,
  &--balance-ranking,
  &--net-deposit-ranking,
  &--loss-ranking,
  &--profit-ranking {
    .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &--symbol-ranking {
    .tr {
      grid-template-columns: 1fr 1fr;
    }
  }

  .input {
    margin-bottom: 0;
  }

  .tr {
    gap: 4px;
    @media screen and (max-width: 1199px) {
      gap: 0;
    }
    &:nth-last-child(3),
    &:nth-last-child(2),
    &:last-child {
      .select__drop {
        top: unset;
        bottom: calc(100% + 10px);
      }
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      .select__drop {
        top: calc(100% + 10px);
        bottom: unset;
      }
    }
  }
  .td {
    padding: 10px 0;
    &:first-child {
      padding-left: 14px;
    }
    &:last-child {
      padding-right: 14px;
    }
    @media screen and (max-width: 1199px) {
      padding: 10px;
      .table-buttons {
        justify-content: flex-start;
      }
    }
  }
}

.td {
  &--hide-mob {
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
  @media screen and (max-width: 1199px) {
    width: 33.3%;
  }
  @media screen and (max-width: 620px) {
    width: 50%;
  }
}

.td-name {
  p {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .select__current-arrow {
    margin-left: 6px;
  }
  &--edit {
    > p {
      color: var(--main-color);
    }
  }
  &--tooltip-type {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &--color-red {
    > p {
      color: var(--red-color);
    }
  }
}

.td-value {
  &--side-type {
    display: inline-flex;
    padding: 0 12px;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }
  &--side-buy {
    background: #4a7a88;
  }
}

.td-hidden-name {
  color: var(--text-main-color3);
  @media screen and (max-width: 1199px) {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
  }
}

.td--mob-hidden {
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.table-header {
  .tr {
    border-bottom: 1px solid var(--border-main-color);
  }
  .td {
    font-weight: 500;
  }
  .select--table .select__current {
    font-weight: 500;
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.table-body {
  font-size: 14px;
  .tr {
    border-bottom: 1px solid var(--border-main-color);
    &:last-child {
      border: none;
    }
    @media screen and (max-width: 1199px) {
      padding: 6px 0;
      align-items: flex-start;
    }
  }
  .td {
    padding: 15px 0;
    @media screen and (max-width: 1199px) {
      padding: 4px 12px;
    }
  }
  .tr-group {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% - 28px);
      transform: translateX(-50%);
      border-bottom: 1px solid var(--border-main-color);
    }
    @media screen and (max-width: 1199px) {
      &:after {
        border-width: 2px;
        width: 100%;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    .tr {
      border-bottom: none;
      &:last-child {
        border-bottom: none !important;
      }
      @media screen and (max-width: 1199px) {
        border-bottom: 1px solid var(--border-main-color);
      }
      &:not(:first-child) .td {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.tr-drop-list {
  display: none;
  &.active {
    display: block;
  }
}

.td-more-info {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  color: var(--text-main-color3);
}

.td--right {
  * {
    justify-content: flex-end !important;
  }
  .flex--column {
    justify-content: flex-start;
    align-items: flex-end;
    @media screen and (max-width: 1199px) {
      padding-top: 10px;
    }
  }
  .checkbox__item {
    justify-content: center !important;
  }
  .dropdown {
    right: 0;
    left: unset;
  }
  @media screen and (max-width: 1199px) {
    * {
      justify-content: flex-start !important;
      align-items: flex-start !important;
      .select__current,
      .select__current-arrow {
        align-items: center !important;
      }
    }
  }
}

.td-ordering {
  display: flex;
  align-items: center;

  & p {
    margin-right: 8px;
  }

  &--order-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.td-input {
  max-width: 100%;
  &--short {
    width: 80px;
  }
  @media screen and (max-width: 1199px) {
    width: 100% !important;
  }
}

.td-symbol {
  &__name {
    display: flex;
    align-items: center;
    gap: 2px;
    text-transform: uppercase;
    span {
      font-size: 12px;
      color: var(--text-main-color3);
    }
  }
  &__count {
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
    height: 20px;
    padding: 0 8px;
    background: var(--main-color);
    border-radius: 4px;
    color: #fff !important;
  }
}

.td-symbol-details {
  font-size: 12px;
  color: var(--text-main-color3);
}

.table-mob-sort {
  display: none;
  align-items: center;
  gap: 8px 16px;
  margin-top: 12px;
  padding: 0 0 8px 14px;
  border-bottom: 1px solid var(--border-main-color);
  .select--table .select__current {
    font-size: 16px;
  }
  .select__drop {
    right: unset;
    left: 0;
  }
  @media screen and (max-width: 1199px) {
    display: flex;
  }
}

.td-flex {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  &__drop-btn {
    display: flex;
    margin-right: 4px;
    padding: 3px 0;
  }
}

.table-buttons {
  position: relative;
  display: inline-flex;
  gap: 10px;
  button {
    margin: 0 !important;
  }
  &__btn {
    &--color-red {
    }
  }
}

.table-buttons-drop {
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  width: max-content;
  border-radius: 12px;
  border: 1px solid var(--border-main-color);
  background: #fff;
  display: none;
  z-index: 10;
  &__item {
    padding: 8px 16px;
    display: block !important;
    height: 39px;
    color: #202020;
    text-align: left;
    border-bottom: 1px solid #eaeaef;
    font-weight: 500;
    &:hover {
      color: var(--main-color);
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &.active {
    display: block;
  }
}

.title-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  &__icon {
    display: flex;
    width: 24px;
  }
}

.block-title {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  &--type2 {
    font-size: 18px;
    font-weight: 600;
  }
  &--fs-18 {
    font-size: 18px;
  }
}

.back-to-btn {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  &__arrow {
    display: flex;
    flex: 0 0 20px;
    svg {
      width: 100%;
    }
  }
}

.mob-nav-btn {
  svg {
    path {
      fill: #fff !important;
    }
  }
}

.forms-row {
  display: grid;
  @media screen and (max-width: 767px) {
    gap: 16px;
  }
  &--admin-management {
    grid-template-columns: 1fr 352px;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
}

.form {
  width: 100%;
  max-width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  border: none;
  &--user-edit {
    width: 352px;
  }
  &--user-edit-new {
    width: 660px;
    margin-top: 0;
    padding: 0;
    border-radius: 0;
  }
  &--admin-management {
    border-radius: 4px;
    padding: 41px 31px;
    @media screen and (max-width: 1024px) {
      padding: 24px 16px;
    }
  }
  &--admin-management-full {
  }
}

.form-submit {
  margin-top: 16px;
  &--mt-type2 {
    margin-top: 32px;
  }
  &--mt-type3 {
    margin-top: 24px;
  }
  &--start {
    justify-content: flex-start;
  }
  &--end {
    justify-content: flex-end;
  }
  &--gap8 {
    gap: 8px;
  }
}

.form-title {
  font-weight: 500;
}

.form-captcha {
  display: flex;
  margin-top: 32px;
  max-width: 100%;
}

.inputs-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 16px;
  &--gap30 {
    gap: 0 30px;
  }
  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
  &--order {
    .input {
      display: grid;
      align-items: center;
      grid-template-columns: 120px 1fr;
      gap: 8px;
      @media screen and (max-width: 475px) {
        grid-template-columns: 1fr;
      }
    }
    .input-name {
      margin-bottom: 0;
    }
  }
}

.value {
  &--fs-14 {
    font-size: 14px;
  }
}
.req {
  color: #f44336;
}
.panel {
  &--content-end {
    justify-content: end;
    @media screen and (max-width: 1199px) {
      justify-content: flex-start;
    }
  }
}

.block {
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 10px;
  &--type2 {
    padding: 16px;
  }
  &--type3 {
    padding: 0 16px;
  }
  &--raidus-5 {
    border-radius: 5px;
  }
  @media screen and (max-width: 1280px) {
    padding: 16px;
  }
  @media screen and (max-width: 720px) {
    border-radius: 0;
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 16px;
  }
  & + .block-tabs {
    margin-top: 30px;
    @media screen and (max-width: 720px) {
      margin-top: 16px;
    }
  }
  & + .block {
    margin-top: 16px;
  }
}

.block-tabs {
  display: flex;
  gap: 8px;

  &__item {
    position: relative;
    padding: 14px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    line-height: 1.38;
    transition: 0.3s;
    color: var(--text-main-color3);
    &:after {
      content: '';
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 6px;
      height: 6px;
      background: #fff;
      opacity: 0;
    }
    &.active,
    &:hover {
      color: var(--text-main-color);
      background: #ffffff;
      &:after {
        opacity: 1;
      }
    }

    &.active {
      font-weight: 500;
    }
    @media screen and (max-width: 720px) {
      padding: 0;
      background: none;
      padding: 0;
      border-bottom: 2px solid transparent;
      border-radius: 0;
      &.active,
      &:hover {
        color: var(--text-main-color);
        background: none;
        border-color: var(--text-main-color);
      }
      &:after {
        display: none;
      }
    }
  }

  @media screen and (max-width: 720px) {
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 8px 16px;
  }
}

.client-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  .title-wrap {
    margin-bottom: 0;
  }
}

.client-account {
  &__title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    margin-top: 16px;
  }
  &__list-item {
    padding: 18px 0;
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    border-top: 1px solid var(--border-main-color);

    @media screen and (max-width: 560px) {
      padding: 12px 0;
      grid-template-columns: repeat(1, 1fr);
      gap: 8px;
    }

    > * {
      &:first-child {
        color: var(--text-main-color3);
        font-weight: 400;
      }
    }
  }
}

.client-list-val {
  display: flex;
  align-items: center;
  gap: 8px;
}

.client-account-button {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.client-verify-box {
  margin-top: 45px;
}

.client-verify {
  max-width: 332px;
}

.client-doc {
  width: 230px;
}

.doc-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    color: var(--text-main-color3);
  }
  &__info {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__info-size {
    color: #d2d2d3;
  }
}

.doc-actions {
  display: flex;
  align-items: center;
  gap: 4px;
  button {
    max-width: 24px;
  }
}

.summary-total-list {
  margin-top: 12px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  @media screen and (max-width: 1180px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &--type2 {
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: 720px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &--with-bg {
    .summary-total-list__item {
      background: #fff;
    }
  }
  &--mb-type2 {
    margin-bottom: 30px;
    @media screen and (max-width: 720px) {
      margin-bottom: 16px;
    }
  }
  &__item {
    padding: 0 12px;
    height: 91px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    border: 1px solid var(--border-main-color);
    border-radius: 5px;
    @media screen and (max-width: 1180px) {
      padding: 12px;
      height: auto;
    }
  }
  &__item-counter {
    font-size: 24px;
    color: var(--main-color);
    span {
      display: inline-block;
      margin-left: 4px;
      font-size: 15px;
      color: var(--text-main-color3);
    }
  }
  &__item-title {
    font-size: 15px;
    color: var(--text-main-color3);
  }
  & + .subtitle {
    margin-top: 16px;
  }
}

.block-nav {
  margin-top: 20px;
  display: flex;
  gap: 8px;
  @media screen and (max-width: 720px) {
    margin-top: 8px;
    gap: 8px 12px;
    flex-wrap: wrap;
  }
  &--big-mt {
    margin-top: 40px;
    @media screen and (max-width: 720px) {
      margin-top: 16px;
    }
  }
  &__item {
    position: relative;
    margin: 0 12px;
    display: block;
    font-size: 16px;
    color: var(--text-main-color3);
    @media screen and (max-width: 720px) {
      margin: 0;
      font-size: 14px;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      left: 0;
      bottom: -9px;
      background: transparent;
      transition: 0.3s;
      @media screen and (max-width: 720px) {
        bottom: -3px;
        height: 3px;
      }
    }

    &.active,
    &:hover {
      color: var(--text-main-color);
      &::before {
        background: var(--main-color);
      }
    }
    &.active {
      font-weight: 500;
    }
  }
}

.copy-button {
  display: flex;
  align-items: center;
  gap: 4px;

  &__text {
    width: 75px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.td-status {
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  background: #00da83;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  &__name {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  &__name-arrow {
    flex: 0 0 6px;
    min-width: 6px;
  }
  &--failed {
    background: #f44336;
  }
  &--in-process,
  &--new {
    background: #4a7a88;
  }
  &--select {
    .td-status__name {
      cursor: pointer;
    }
    .dropdown {
      width: max-content;
      min-width: 100px;
    }
  }

  &.active {
    .dropdown {
      display: block;
    }
  }
}

.ref-commissions-form {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 8px 16px;
  &__input {
    width: 522px;
    max-width: 100%;
  }
}

.dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 4px);
  padding: 8px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  z-index: 2;

  ul {
    li {
      text-align: center;
      > a,
      > button {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 8px 12px;
        border-radius: 4px;
        font-size: 13px;
        transition: 0.2s linear;
        &:hover {
          color: #173b58;
          background: #f5f5f5;
        }
      }
    }
  }
  &--td-drop {
    width: 170px;
    padding: 0;
    ul {
      li {
        > a,
        > button {
          justify-content: center !important;
          padding: 8px 12px;
          font-size: 14px;
          border-radius: 0;
        }
        &:first-child {
          > a,
          > button {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          > a,
          > button {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}

.popup-reason {
  margin-top: 12px;
}

.details-box {
  margin-bottom: 24px;
  background: #f7f8f9;
  border: 1px solid var(--border-main-color);
  border-radius: 15px;
  padding: 24px 16px;
}

.details-item {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 4px 8px;
  align-items: center;
  font-size: 14px;
  &__name {
    color: var(--text-main-color3);
  }
  &__value {
    text-align: right;
  }
  & + .details-item {
    margin-top: 8px;
  }
}

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &__btn {
    color: var(--main-color);
    text-transform: uppercase;
    transition: 0.15s ease-in;
    &:hover {
      color: var(--main-color-hover);
    }
  }
}

.dashboard-header-info {
  &__title {
    font-size: 24px;
    color: var(--main-color);
  }
  &__value {
    margin-top: 4px;
    font-size: 15px;
    color: var(--text-main-color3);
  }
}

.dashboard-chart {
  display: flex;
  img,
  iframe {
    width: 100%;
  }
}

.dashboard-drop {
  display: none;
  padding-top: 16px;
  &.active {
    display: block;
  }
}

.dashboard-stat-box {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  gap: 16px;
  margin-top: 30px;
  @media screen and (max-width: 1199px) {
    grid-template-columns: minmax(100px, 1fr);
  }
  @media screen and (max-width: 720px) {
    margin-top: 16px;
  }
}

.dashboard-stat {
  .table-block {
    margin-top: 12px;
  }
}

.graph-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.input-with-element {
  display: flex;
  align-items: center;
  gap: 16px;
  > div:first-child {
    flex: 1;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.link {
  color: var(--text-main-color2);
  &:hover {
    color: var(--text-main-color);
  }
  &--type2 {
    text-decoration: underline !important;
  }
}

.tooltip-info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-color3);
  &__icon {
    display: flex;
    flex: 0 0 16px;
  }
  & + .tooltip-info {
    margin-top: 16px;
  }
}

.blue {
  color: #4376fe !important;
}

.red {
  color: #e04141 !important;
}

.red-bg {
  padding: 4px 10px;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
  background: #f44336;
  border-radius: 10px;
}

.green {
  color: #00da83 !important;
}

.yellow {
  color: #ff9f0a !important;
}

.yellow-bg {
  padding: 4px 10px;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
  background: #ff9f0a;
  border-radius: 10px;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.grey {
  color: #777e90 !important;
}

.grey-bg {
  padding: 4px 10px;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
  background: #4a7a88;
  border-radius: 10px;
}

.input-container-dropdown {
  margin-bottom: 0;
}
.table-dropdown-no-result {
  text-align: center;
}

.forgot-modal--email {
  display: inline-block;
}

.button.btn-copy {
  justify-content: center !important;
  min-width: 60px;
  height: 33px;
  word-break: keep-all;
}

.td-copy-btn {
  padding-right: 35px !important;
}

.to-profile-section {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pagination-select-block {
  margin: 10px 10px;
}

.pagination-block {
  margin-bottom: 100px;
}

.new-password {
  margin-top: 20px;
}

.pagination-rankings {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

.column-visibility {
  label {
    margin-right: 25px;
  }
}

.account-trade-column-visibility {
  label {
    margin-right: 30px;
  }
  div {
    margin-top: 25px;
  }
}

.switch-table-button {
  position: relative;
  right: 65px;
  top: -84px;
}

.settings-menu-row {
  cursor: pointer;
}

.settings-menu {
  list-style: none;
  margin: 0;
  padding: 0 0 0 20px;

  .disabled {
    color: #ccc;
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;
  }

  .disabled:hover {
    color: #ccc;
    text-decoration: none;
    cursor: not-allowed;
  }
}

.settings-menu li {
  margin: 5px 0;
}

.trading-conditions-other-settings {
  padding-top: 40px;

  div {
    display: flex;
    margin-bottom: 15px;
  }

  .demo-account {
    span {
      margin-left: 20px;
    }
  }

  .netting {
    span {
      margin-left: 74px;
    }
  }

  .scalping {
    span {
      margin-left: 65px;
    }
    &__label-switch {
      padding-top: 10px;
    }
    &__options {
      margin-left: 30px;
    }
  }
}

@import 'ui-modifiers.scss';

@import '_media.scss';
