.input-notification--error {
  color: var(--ui-error-color);
  font-size: 14px;
}

.PhoneInputCountry {
  display: none;
}

.select.input--error {
  border: 2px solid var(--ui-error-color);
}

.search-by .select-item {
  flex: 0 0 120px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input--number .input-item {
  padding: 0 50px;
  text-align: center;
}

.inputs-row--order {
  .input-notification--error {
    grid-column: 2 / 3;
  }
  .input--datepicker {
    grid-column: 1 / 3;
  }
}
