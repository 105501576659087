@import '_reset.scss';
@import 'ui-kit.scss';

* {
  box-sizing: border-box;
}

button {
  padding: 0;
  border: none;
  background: none;
}

img {
  width: 100%;
}

svg {
  width: 100%;
  height: auto;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

html body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.header {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 15px;
  background: #c4c4c4;
  &__logo {
    align-items: center;
    justify-content: center;
    background: #3b3d48;
    border-radius: 50%;
    img {

      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.mob-nav-btn {
  display: none;
  margin-right: 15px;
  width: 16px;
  svg path {
    fill: #000 !important;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}

.authorization-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 50px 0 30px 0;
}

.authorization-form {
  width: 352px;
  max-width: calc(100% - 20px);
  margin: 0 auto;
  padding: 38px;
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 8px;
  &__logo {
    margin: 0 auto 22px auto;
    width: 50px;
  }
  &__body {
    margin-top: 22px;
  }
}

.form-title {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

.form-submit {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 24px;
  &--start {
    justify-content: flex-start;
  }
  &--margin-none {
    margin-top: 0;
  }
}

.sidebar {
  width: 250px;
  padding: 12px;
  background: #dadada;
  transition: all 0.3s ease;
  &__action {
    position: relative;
    display: flex;
    flex: 0 0 14px;
    max-width: 14px;
    margin: 0 0 0 auto;
    z-index: 2;
    .stroke path {
      stroke: #3b3d48;
    }
  }
}

.main-section {
  display: flex;
  min-height: calc(100vh - 64px);
  transition: all 0.3s ease;
  &.minimized {
    .sidebar {
      width: 50px;
      padding: 12px 0;
      transition: width 0.3s ease;
      &__action {
        margin: 0 auto;
        transform: rotate(180deg);
      }
    }
    .sidebar-nav__subitem {
      display: none;
    }
    .sidebar-nav__list {
      > li {
        > a,
        > button {
          justify-content: center;
          padding: 12px 0;
          font-size: 0;
        }
      }
    }
    .sidebar-nav__icon {
      margin: 0;
    }
    .main-content {
      width: calc(100% - 50px);
    }
  }
}

.main-content {
  width: calc(100% - 250px);
  padding: 30px 16px;
  transition: width 0.3s ease;
  &--flex {
    display: flex;
    flex-direction: column;
  }
}

.sidebar-nav {
  margin-top: 15px;
  &__icon {
    display: flex;
    align-items: center;
    flex: 0 0 20px;
    max-width: 20px;
    margin: 0 10px 0 0;
    svg {
      max-height: 18px;
      width: 100%;
    }
    .stroke path {
      stroke: var(--font-color-third);
      transition: all 0.3s ease;
    }
    .fill path {
      fill: var(--font-color-third);
      transition: all 0.3s ease;
    }
  }
  &__subitem {
    padding: 13px 12px 13px 47px;

    &--active {
      .sidebar-nav__sublink {
        font-weight: bold;
      }
    }
  }
  &__sublink {
    color: #778192;
    font-size: 15px;
    line-height: 22px;

    &:hover {
      font-weight: bold;
    }
  }
  &__list {
    > li {
      > a,
      > button {
        display: flex;
        align-items: center;
        padding: 12px 18px;
        border-radius: 4px;
        font-size: 15px;
        color: var(--font-color-third);
        transition: all 0.3s ease;

        &.active,
        &:hover {
          background: #999999;
          color: #fff;
          font-weight: 500;
          .sidebar-nav__icon {
            .stroke path {
              stroke: #fff;
            }
            .fill path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

.title {
  font-size: 29px;
  line-height: 1.4;
}

.title-block {
  display: flex;
  align-items: center;
  &__icon {
    display: flex;
    align-items: center;
    width: 34px;
    margin-right: 12px;
    svg {
      width: 100%;
      height: auto;
    }
  }
}

.count-label {
  margin: 30px 0 0 0;
  font-size: 14px;
}

.table-block {
  margin-top: 22px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.table {
  font-size: 14px;
  .tr {
    width: 100%;
  }
  &--transaction {
    background: #f9f9f9;
    & .tr {
      grid-template-columns: 1.7fr 1fr 1fr;
    }
  }
  &--transaction-history {
    & .tr {
      grid-template-columns: 2.4fr 1.8fr 1fr 1fr 1fr;
    }
  }
  &--transactions {
    & .tr {
      grid-template-columns: 1.8fr 2.4fr 1.3fr 1fr 1fr 105px;
    }
  }
  &--list-admin {
    & .tr {
      grid-template-columns: 1fr 1fr 1.2fr 2fr 1fr 1fr 0.7fr;
    }
  }
  &--medium {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &--wallet {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1.1fr 1.1fr 1.4fr 1.5fr;
    }
  }
  &__block-title {
    display: block;
    padding: 18px 25px 10px 25px;
    font-size: 16px;
    line-height: 24px;
  }
}

.table-transaction {
  border: 1px solid #e3e7ee;
  &__name {
    display: flex;
    align-items: center;
    padding: 18px 16px;
  }
  &__icon {
    display: flex;
    align-items: center;
    width: 15px;
    height: 15px;
  }
  &__title {
    display: block;
    padding-left: 14px;
    color: #27ae60;
    font-size: 14px;
    line-height: 18px;
  }
}

.table-desc {
  display: block;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #9ea2a9;
}
.tr {
  display: grid;
  align-items: center;
}

.td {
  padding: 12px 10px;
  word-break: break-word;
  &:first-child {
    padding-left: 25px;
  }
  &:last-child {
    padding-right: 25px;
  }
  &--right {
    text-align: right;
  }
  &--padding-left {
    padding-left: 25px;
  }
}

.table-header {
  .tr {
    border-bottom: 1px solid #e8e8e8;
  }
}

.table-body {
  .tr {
    border-bottom: 1px solid #e8e8e8;
    &:last-child,
    &:only-child {
      border: none;
    }
  }
}

.td-name {
  font-weight: 500;
  font-size: 12px;
  &--flex {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 14px;
  }
}

.table--user-management {
  .tr {
    grid-template-columns: 100px 1fr 1.2fr 1fr 1fr 0.8fr 140px 80px;
  }
}

.table-buttons {
  display: flex;
  button {
    flex: 0 0 15px;
    max-width: 15px;
    .stroke path {
      stroke: var(--grey);
    }
    &.active {
      .stroke path {
        stroke: var(--main-color);
      }
    }
  }
  button + button {
    margin-left: 10px;
  }
  &--right {
    justify-content: flex-end;
  }
}

.td-hidden-name {
  display: none;
  font-weight: 500;
}

.td-title {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: rgba(37, 38, 45, 0.5);
}

.td-desc {
  font-size: 14px;
  &--grey {
    color: rgba(37, 38, 45, 0.5);
  }
}

.link {
  color: #599dc4;
  text-decoration: underline;
}

.admin {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  &__logout {
    display: flex;
    flex: 0 0 15px;
    max-width: 15px;
    margin-left: 14px;
    .stroke path {
      stroke: #fff;
    }
  }
}

.status {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 11px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  &--verified {
    background: #d7ebd3;
    color: #5bc044;
  }
}

.pagination-block {
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
}

.pagination {
  display: flex;
  align-items: center;
  > li {
    > a,
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: #fff;
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      font-size: 14px;
      color: var(--font-color);
      transition: all 0.15s ease;
      svg {
        width: 9px;
      }
      &.arrow {
        .stroke path {
          stroke: var(--main-color);
        }
      }
      &.disabled {
        cursor: default;
        .stroke path {
          stroke: #9ea2a9;
        }
      }
      &.active {
        border-color: var(--main-color);
        color: var(--main-color);
      }
      &:hover {
        color: var(--main-color);
      }
    }
    & + li {
      margin-left: 10px;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__item {
    margin-right: 15px;
    opacity: 0.5;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    &:last-child {
      opacity: 1;
    }
  }
  &__icon {
    display: flex;
    width: 9px;
    margin-right: 15px;
    opacity: 0.5;
    .stroke path {
      stroke: #000;
    }
  }
}

.panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  &--margin-none {
    margin-top: 0;
  }
}

.user-block {
  display: flex;
  align-items: center;
  &__name {
    margin-right: 12px;
    font-size: 14px;
  }
}

.back-btn {
  display: flex;
  flex: 0 0 22px;
  width: 22px;
  max-width: 22px;
  margin-right: 12px;
  .fill path {
    fill: #000;
  }
  &--small {
    width: 10px;
    max-width: 10px;
    flex: 0 0 10px;
  }
}

.filters {
  display: flex;
  margin-left: auto;
}

.filter {
  width: 130px;
  margin-right: 16px;
  &:last-child {
    margin: 0;
  }
}

.content-block {
  margin-top: 22px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  &__inside {
    padding: 30px;
    &--bt {
      border-top: 1px solid #e3e7ee;
    }
  }
}

.forms-block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}

.block-title {
  font-size: 16px;
  font-weight: 500;
}

.user-transaction {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  &__block {
    &:not(:last-child) {
      margin-right: 70px;
    }
  }
}

.personal-info {
  &__name {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  &__desc {
    display: flex;
    align-items: flex-end;
    &:hover {
      .personal-info__text {
        text-decoration: underline;
      }
    }
  }
  &__text {
    display: block;
    font-size: 16px;
    line-height: 18px;
    padding-right: 8px;
  }
  &__edit {
    width: 30px;
    height: 30px;
    background: #27ae60;
    border-radius: 3px;
    padding: 0;
    min-width: 30px;
    &:hover {
      background: #1b9850;
    }
  }
}

.operation {
  display: flex;
  align-items: center;
  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: #27ae60;
    width: 30px;
    height: 30px;
  }
  &__status-icon {
    display: flex;
    align-items: center;
  }
  &__info {
    padding-left: 8px;
    flex: 1;
  }
  &__title {
    display: block;
    font-size: 14px;
    line-height: 18px;
  }
  &__desc {
    display: block;
    padding-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #9ea2a9;
  }
}

.level {
  display: flex;
  align-items: center;
  margin-left: auto;
  &__title {
    display: block;
    padding-right: 24px;
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;
  }
  .select--small .select__drop {
    min-width: 100%;
  }
}

.stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__header {
    display: flex;
    align-items: baseline;
  }
  &__title {
    display: block;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    padding-right: 16px;
  }
  &__desc {
    display: block;
    font-size: 14px;
    line-height: 16px;
  }
  &__info {
    max-width: 515px;
    margin-right: 30px;
    &--full {
      max-width: 100%;
    }
  }
  &__list {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 16px;
  }
}

.stats-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  width: 48%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  &--small {
    flex-direction: column;
    width: 120px;
    align-items: flex-start;
    .stats-item__desc {
      padding-top: 5px;
    }
  }
  &--active {
    background-color: var(--main-color);
    color: #fff;
  }
  &__name {
    display: flex;
    align-items: center;
  }
  &__img {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #c4c4c4;
    margin-right: 6px;
  }
  &__title {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  &__desc {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
}

.chart {
  margin-right: 70px;
}

.forms-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.forms-block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  &--big {
    max-width: 674px;
    flex: 1;
    .form {
      width: 100%;
      padding: 10px 57px 30px 30px;
    }
    .input__name {
      font-size: 20px;
    }
  }
}

.trade-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  padding: 15px;
  margin: 0 auto;
}

.form {
  margin-top: 22px;
  width: 355px;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 4px;
  padding: 30px;
  &--settings {
    width: 352px;
  }
  &--second-type {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    border: none;
    margin-top: 11px;
    .form__content {
      flex: 1;
      padding-right: 34px;
      margin-top: 0;
    }
    .input {
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  &__content {
    margin-top: 20px;
  }
}

.radio-row {
  display: flex;
  gap: 20px;
}

.table-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.section-block {
  padding-top: 30px;
}

.info-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    .info-dropdown__title {
      color: var(--purple);
    }
    .info-dropdown__menu {
      display: block;
    }
  }
  &__menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 83%;
    padding-top: 6px;
    z-index: 1;
  }
  &__list {
    background: #f6f6f6;
    border: 1px solid #e3e7ee;
    padding: 4px 0;
    border-radius: 4px;
  }
  &__item {
    display: block;
    padding: 6px 11px;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    &:not(:last-child) {
      box-shadow: inset 0px -1px 0px #e8e8e8;
    }
  }
}

.info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.reports {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 30px;
  &__item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.report-item {
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 4px;
  padding: 28px 32px;
  width: 352px;
  &__status {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 10px;
    background: #ffd8d8;
    border-radius: 11px;
    color: #e46d6d;
  }
  &__img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
  }

  &__title {
    display: flex;
    align-items: center;
  }
  &__icon {
    display: flex;
    align-items: center;
    width: 18px;
    height: 15px;
  }

  &__text {
    display: block;
    padding-left: 12px;
    font-size: 18px;
    line-height: 18px;
  }
  &__info {
    padding-right: 20px;
  }
  &__price {
    display: block;
    padding-top: 28px;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
  }
  &__footer {
    padding-top: 28px;
  }
}

.connect-data {
  max-width: 720px;
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 4px;
  padding: 22px 32px 36px 32px;
  &--margin-top {
    margin-top: 16px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    display: block;
    font-size: 18px;
    line-height: 18px;
    color: #5bc044;
  }
  &__send {
    height: 48px;
  }
}

.close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e7ee;
  border-radius: 50%;
  &__icon {
    display: flex;
    width: 10px;
  }
  &:hover {
    background-color: #e3e7ee;
  }
}

@import 'admin__media.scss';
