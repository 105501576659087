.edit-user-form {
  .source-select {
    .select__current {
      text-transform: capitalize;
    }
    .select__drop {
      .select__drop-item {
        li {
          * {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
