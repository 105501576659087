.notification {
  transform: none;
  box-shadow: none;
  padding: 5px 10px;
}

.notification__error {
  color: var(--ui-error-color);
}

.Toastify {
  .Toastify__toast {
    border-radius: 8px;
  }
  .Toastify__toast-theme--dark {
    background: linear-gradient(
      96.96deg,
      rgba(157, 231, 189, 0.4) 21.34%,
      rgba(54, 205, 119, 0.4) 119.18%
    );
    backdrop-filter: blur(20px);
    // .notification__text {
    //   color: #fff;
    // }
  }
  .Toastify__toast--success {
    border: 1px solid #00ba88;
  }
  .Toastify__toast--error {
    border: 1px solid #fd3232;
  }
}
