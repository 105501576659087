.button {
  min-width: 80px;
  height: 44px;
  align-items: center;
  background: var(--main-color);
  gap: 12px;
  color: #fff;
  border: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 5px;
  letter-spacing: 0.002em;
  transition: 0.2s;

  svg {
    min-width: 15px;
  }

  &:hover {
    background: var(--main-color-hover);
  }
  &:disabled {
    color: var(--text-main-color3);
    background: #e6e8ec;
  }

  &--icon {
    padding: 10px;
    width: 44px;
    min-width: 44px;
  }

  &--type2 {
    color: var(--text-color-main);
    border: 1px solid #777e90;
    background: none;

    &:hover {
      color: var(--main-color);
      border-color: var(--main-color);
      background: none;
    }
  }
  &--type3 {
    color: var(--text-color-main);
    background: #fff;
    &:hover {
      color: var(--main-color);
      background: #fff;
    }
  }
  &--type4 {
    background-color: #fff;
    border: 1px solid #f44336;
    color: #f44336;
    &:hover {
      background: #f44336;
      color: #fff;
      .btn-icon svg path {
        stroke: #fff;
      }
    }
  }
  &--type-text {
    padding: 0 8px;
    min-width: auto;
    width: auto;
    height: auto;
    color: var(--main-color);
    border: none;
    background: none;

    &:hover {
      color: var(--main-color-hover);
      border: none;
      background: none;
    }
  }
  &--text-green {
    color: #00da83;
    &:hover {
      color: #02c074;
    }
  }
  &--type-red {
    background: #f44336;
    &:hover {
      background: #d63428;
    }
  }
  &--width-120 {
    width: 120px;
  }
  &--width-165 {
    width: 165px;
  }

  &--size2 {
    height: 42px;
  }
}

.btn-icon {
  svg path {
    transition: 0.2s;
  }
}
.input-icon {
  width: 22px;
}

.input {
  margin-bottom: 16px;
  &__notification {
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    color: #e04141;
    a {
      font-size: 14px;
      color: #4376fe;
    }
    ul {
      padding-left: 10px;
      list-style-type: disc;
      list-style-position: inside;
    }
  }
  &--error {
    .input-item {
      border: 1px solid #f44336;
    }
  }
}

.input-name {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  &--color2 {
    color: var(--text-main-color3);
  }
  &--with-hint {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

div.input-item {
  display: flex;
  align-items: center;
}

.input-item {
  height: 44px;
  border-color: var(--border-main-color);
  border-radius: 5px;
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(119, 126, 144, 0.5);
    opacity: 1;
  }
  &--width-75 {
    width: 75%;
  }
  &--left-icon-bigger {
    padding-left: 50px;
  }
  &--pr25 {
    padding-right: 25px;
  }
  &--pr50 {
    padding-right: 50px;
  }
  &--pr75 {
    padding-right: 75px;
  }
  &--pr100 {
    padding-right: 100px;
  }
  &--pr125 {
    padding-right: 125px;
  }
  &--pr150 {
    padding-right: 150px;
  }
  &--pr175 {
    padding-right: 175px;
  }

  &--type2 {
    height: 40px;
  }
  &--type-td {
    max-width: 175px;
    height: 30px;
    font-size: 14px !important;

    &::placeholder {
      font-size: inherit;
    }
  }
  &--user-data {
    max-width: 100%;
    height: 40px;
    width: 280px;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  &--td-type {
    height: 34px;
    font-size: 14px !important;
    &::placeholder {
      font-size: inherit;
    }
  }
}

.textarea {
  border-color: var(--border-main-color);
  border-radius: 4px;
  textarea {
    min-height: 61px;
  }
  &--height-type2 {
    textarea {
      min-height: 100px;
    }
  }
}

.checkbox {
  &__item {
    justify-content: center !important;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    color: #173b58;
    line-height: 1.3;
  }
  input:checked ~ .checkbox__item {
    background: var(--main-color);
    border-color: var(--main-color);
  }
}

.select-block {
  margin-bottom: 24px;
  &__name {
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--main-color);
    font-weight: 500;
  }
  &--width-170 {
    width: 170px;
  }
}

.select {
  height: 44px;
  border: 1px solid var(--border-main-color);
  border-radius: 5px;

  &--td-leverage {
    .select__drop {
      right: 0;
      min-width: 100px;
    }

    .select__drop-scroll {
      max-height: 218px;
    }
  }

  &__current {
    align-items: center !important;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: var(--text-main-color);
    &--placeholder {
      color: rgba(119, 126, 144, 0.5);
      opacity: 1;
    }
  }
  &__current-arrow {
    align-items: center !important;
    svg path {
      stroke: var(--text-main-color);
    }
  }
  &__drop-scroll {
    max-height: 204px;
  }
  &__drop {
    padding: 0;
    right: -1px;
    left: auto;
    width: 100%;
    border: none;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 4px;
    background: #ffffff;

    ul {
      > li {
        padding: 0 16px;
        transition: all 0.15s ease;

        > button {
          padding: 12px 0;
          font-weight: 500;
          font-size: 14px;
          color: var(--text-main-color3);
          border-radius: 0;
          transition: all 0.15s ease;
          &:hover,
          &.active {
            background: none;
            color: var(--text-main-color);
          }
        }
        & + li {
          > button {
            border-top: 1px solid var(--border-color2);
          }
        }
        &:first-child {
          > button {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          > button {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
    ::-webkit-scrollbar-track {
      background: #e6e8ec;
    }
  }
  &--table {
    height: auto;
    width: auto;
    border: none;
    background: none;
    .select__current {
      padding: 0;
      justify-content: flex-start;
      font-size: 12px;
      font-weight: 400;
      word-break: normal;
      color: var(--text-main-color);
    }
    .select__drop {
      padding: 0;
      width: 170px;
      right: 0;
      ul {
        > li {
          padding: 0;
          > button {
            padding: 8px 12px;
            font-size: 12px;
            text-align: center;
            &:hover {
              background: var(--border-color2);
              color: var(--text-main-color);
            }
          }
        }
      }
    }
  }
  &--td-content {
    display: inline-flex;
    height: auto;
    background: none;
    border: none;
    .select__current {
      padding: 0;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 400;
      word-break: normal;
      color: var(--text-main-color);
    }
    .select__drop {
      padding: 4px;
      width: max-content;
      ul {
        > li {
          padding: 0;
          > button,
          > a {
            padding: 8px 12px;
            border-radius: 4px;
            font-size: 12px;
            text-align: left;
          }
          & + li {
            > button,
            > a {
              //border: none;
            }
          }
        }
      }
    }
  }
  &--td-leverage {
    .select__drop {
      ul {
        > li {
          > a,
          > button {
            color: var(--text-main-color);
            &:hover {
              background: #e6e8ec;
            }
          }
          & + li {
            > a,
            > button {
              border: none;
            }
          }
        }
      }
    }
  }
  &--red-current {
    .select__current {
      color: #f44336;
    }
    .select__current-arrow {
      svg path {
        stroke: #f44336;
      }
    }
  }
}

.popup-window {
  background: rgba(1, 30, 38, 0.5);
  backdrop-filter: blur(0);
  &--more-index {
    z-index: 210;
  }
}

.popup {
  width: 365px;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  padding: 24px;
  &--width-400 {
    width: 400px;
  }
  &--width-500 {
    width: 500px !important;
  }
  &--width-880 {
    width: 880px;
  }
  &__close {
    position: relative;
    right: 0;
    top: 0;
    width: 12px;
    margin: -2px -2px 0 auto;
  }
}

.popup-header {
  justify-content: center;
  &__title-block {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__title {
    display: inline-flex;
    gap: 8px;
    font-size: 24px;
    font-weight: 600;
    a {
      color: var(--text-main-color2);
    }
  }
}

.popup-body {
  &--type2 {
    margin-top: 24px;
  }
}

.popup-text {
  font-weight: 400;
  font-size: 16px;
  color: var(--text-main-color3);
  line-height: 1.38;
  &--mt {
    margin-top: 24px;
  }
  p a {
    color: var(--link-color);
  }
  &--fs-16 {
    font-size: 16px;
    line-height: 1.5;
  }
}

.create-order-save-button-wrapper {
  justify-content: flex-end;
}

.client-trade-nav-items-block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.trade-add-order-user-dropdown {
  display: flex;

  label {
    padding-right: 20px;
  }
}

.add-order-tab-buttons {
  margin: 20px 20px 20px 0;
}

.popup-submit {
  gap: 16px;
  align-items: center;
  &--column {
    flex-direction: column;
  }
  &--start {
    justify-content: flex-start;
  }
  &--sb {
    justify-content: space-between;
  }
  &__group {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    @media screen and (max-width: 475px) {
      width: 100%;
      .button {
        width: 100%;
      }
    }
  }
  &--720-wrap {
    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }
  }
  &--mob-column {
    @media screen and (max-width: 475px) {
      flex-direction: column;
      .button {
        width: 100%;
      }
    }
  }
}

.pagination-block {
  margin-top: 16px;
}

.pagination {
  margin: 0;
  gap: 8px;
  > li {
    padding: 0;
    & + li {
      margin-left: 0;
    }
    > a,
    > button {
      margin-top: 0;
      min-width: 32px;
      width: 32px;
      height: 32px;
      color: #777e90;
      border-color: #777e90;

      &.active,
      &:hover {
        border-color: #3f51b5;
        color: #3f51b5;
        background: #fff;
      }
      &.disabled {
        background: #fff !important;
        border-color: #777e90 !important;
      }
      &:not(.disabled) {
        &:hover,
        &.active {
          color: #3f51b5;
          border-color: #3f51b5;
          background: #fff;
          .stroke path {
            stroke: #3f51b5;
          }
        }
      }
      &.arrow {
        cursor: pointer;
        svg {
          path {
            stroke: #3f51b5;
          }
        }
        &.disabled {
          > a,
          > button {
            border-color: #e6e8ec;
            pointer-events: none;
          }
          svg {
            opacity: 0.3 !important;
          }
        }
      }
      &.disabled {
        cursor: default;
        .stroke path {
          stroke: #777e90;
        }
      }
    }
  }
}

.radio {
  margin-bottom: 12px;
  &__label {
    gap: 12px;
  }
  &__item {
    min-width: 24px;
    width: 24px;
    height: 24px;
    border: 1px solid var(--border-main-color);
    border-radius: 100px;
  }

  &__text {
    margin-left: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    color: var(--text-color-main);
  }

  input:checked ~ .radio__item {
    background: none;
    border-color: var(--accent-color);
    &::after {
      width: 12px;
      height: 12px;
      background: var(--accent-color);
    }
  }
}

.switch {
  &__label {
    display: flex;
    gap: 24px;
  }
  &__toggler {
    width: 42px;
    height: 26px;
    background: #e6e8ec;
    border: none;
    transition: all 0.3s;
    &::before {
      width: 21px;
      height: 21px;
      background: #fff;
    }
  }
  &__text {
    font-size: 14px;
    color: #777e90;
  }

  input:checked ~ .switch__toggler {
    background: var(--main-color);
    &:before {
      left: 18px;
      background: #fff;
    }
  }
  &--green {
    input:checked ~ .switch__toggler {
      background: #34c759;
    }
  }
}

.table-buttons {
  button {
    flex: 0 0 18px;
    max-width: 18px;
  }
}

.tooltip-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tooltip {
  padding: 16px 14px;
  background: #ffffff;
  border: 1px solid var(--border-main-color);
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  top: calc(100% + 12px);
  @media screen and (max-width: 767px) {
    max-width: 260px;
  }
  &::after {
    display: none;
  }
  &--arrow-true {
    &:after {
      display: block;
      border-bottom-color: #32383e;
    }
  }
  &--width-200 {
    width: 200px !important;
  }
  &--width-500 {
    width: 500px !important;
  }
  &__text {
    color: #173b58;
  }
  &--left {
    left: -20px;
  }
  &--arrow-top-left {
    &:after {
      top: -11px;
      left: 21px;
    }
  }
  &--dark {
    background: #32383e;
    border: none;
    .tooltip__text {
      color: var(--text-color-white);
    }
  }
}

.notification-wrapper {
  position: fixed;
  right: 16px;
  top: 20px;
  @media screen and (max-width: 767px) {
    top: 60px;
  }

  @media screen and (max-width: 520px) {
    max-width: calc(100% - 16px);
  }
}

.notification {
  display: flex;
  width: 422px;
  padding: 24px 0;
  border-radius: 12px;
  background: #fff !important;
  @media screen and (max-width: 520px) {
    max-width: calc(100% - 16px);
  }

  &__icon {
    width: 64px;
    min-width: 64px;
    height: auto;
    background: none;
    border-radius: 0;
    svg {
      width: 24px;
    }
  }
  &__info {
    padding: 0 24px;
    padding-left: 0;
  }
  &__title {
    font-size: 18px;
    color: var(--text-main-color) !important;
  }
  &__text {
    margin-top: 4px;
    font-size: 14px;
    color: var(--text-main-color3) !important;
  }

  &--successful {
    border: 1px solid #00ba88;
  }
  &--error {
    border: 1px solid #fd3232;
  }
  @media screen and (max-width: 767px) {
    padding: 16px 0;
  }
  @media screen and (max-width: 520px) {
    margin-left: auto;
  }
}
